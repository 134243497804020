import React, { Component } from 'react'
import './FleetService.scss';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

let countryHasCommingDelivery = ['fr', 'us', 'gb', 'th', 'ee']
let countryHasCommingIntercity = ['fr', 'th', 'ee']

class FleetService extends Component {
  render() {
    const country = this.props.countryCode
    return (
      <section id="service" className="homePage">
        <div className="h-container">
          <h2>
            {<FormattedMessage
              id="home.service.title_1"
              values={{ br_ms: <br className="d-block d-md-none" /> }}
            />} <br />
            {<FormattedMessage
              id="home.service.title_2"
              values={{ br_ms: <br className="d-block d-md-none" /> }}
            />}
          </h2>
          <div className={country == 'vn' ? 'be_driver_main vn' : 'be_driver_main'}>
            <div className="be_driver_content be_driver_content_1" >
              <img src={`../../../${country}/images/services/city.gif`} className="iconHome" />
              <h4><FormattedMessage id="home.service.content_1.title" /></h4>
              <p><FormattedMessage id="home.service.content_1.text" /></p>
            </div>
            <div className="be_driver_content be_driver_content_2">
              <img src={`../../../${country}/images/services/delivery.gif`} className="iconHome" />
              <h4><FormattedMessage id="home.service.content_3.title" /></h4>
              <p>
                <FormattedMessage id="home.service.content_3.text" />
                {
                  (countryHasCommingDelivery.indexOf(country) >= 0) &&
                  <p className="soon">
                    <FormattedMessage id="home.service.comming_soon" />
                  </p>
                }
              </p>
            </div>
            <div className="be_driver_content be_driver_content_3">
              <img src={`../../../${country}/images/services/intercity.gif`} className="iconHome" />
              <h4><FormattedMessage id="home.service.content_2.title" /></h4>
              <p>
                <FormattedMessage id="home.service.content_2.text" />
                {
                  (countryHasCommingIntercity.indexOf(country) >= 0) &&
                  <p className="soon">
                    <FormattedMessage id="home.service.comming_soon" />
                  </p>
                }
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default injectIntl(FleetService)

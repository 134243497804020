import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';

import './YourOwnBoss.scss';
import { Link } from 'gatsby';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
class YourOwnBoss extends Component {
	render() {
		const countryCode = this.props.countryCode
		const languageCode = this.props.intl.locale || "en"
		return (
				<Row className="your-own-boss-row">
					{/* <div className="your-own-boss"> */}
					<Col md={{ span: 7, order: 'last' }} >
						<div className="your-own-boss image-content">
							<img
								className="your-own-boss image"
								src={`../../../${countryCode}/images/img2.png`}
							/>
						</div>
					</Col>
					<Col md={{ span: 5, order: 'first' }}  >
						<div className="your-own-boss content">
							<h1>
								<FormattedMessage 
									id="home.yourOwnBoss.title"
									values={{ br: <br />  }}
								/>
							</h1>
							<p>
								<FormattedMessage 
									id="home.yourOwnBoss.content"
								/>
							</p>
							<div className="your-own-boss learn-more" >
								<div className="item-learn-more">
									<Link to={`driver`}>
										<span className='title-learn-more'><FormattedMessage id="home.learnMore" /></span>
										<span className='icon'>
											<img
												className="icon-image"
												src={`../../../icon/Arrow.svg`}
											/>
										</span>
									</Link>
								</div>
							</div>
						</div>
					</Col>
			{/* </div> */}
				</Row>
		)
	}
}

export default injectIntl(YourOwnBoss)


import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';

import './FleetCover.scss';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
class FleetCover extends Component {
	render() {
		const countryCode = this.props.countryCode
		return (
			<Row className="fleet-cover-row">
				<Col md={7} className="left-col">
					<div className="fleet-cover image-content">
						<img
							className="fleet-cover image"
							src={`../../../${countryCode}/images/img3.png`}
						/>
					</div>
				</Col>
				<Col md={5} className="right-col">
					<div className="fleet-cover content">
						<h1>
							<FormattedMessage id="home.fleetCover.title" />
						</h1>
						<p>
							<FormattedMessage id="home.fleetCover.content" />
						</p>
					</div>
				</Col>
			</Row>
		)
	}
}

export default injectIntl(FleetCover)

import React from 'react';
import BtnAndroid from '../../components/HImage/BtnDowloadAndroid';
import BtnIos from '../../components/HImage/BtnDownloadIOS';
import BtnHuawei from '../../components/HImage/BtnDownloadHuawei';
import { getHomeBannerUS } from '../../constants/commonFunctions';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";
import link from '../../../config/link';
import "react-multi-carousel/lib/styles.css";
import './banner.scss'

const Banner = ({ intl, countryCode, fleetId, nameZoneUS, appGalleryPax }) => {
  return (
    <section className="banner">
      {
        countryCode == 'us' ? (
          <div className="bannerImg">
            <img src={getHomeBannerUS(nameZoneUS) && getHomeBannerUS(nameZoneUS).mobile} className="imgBaner d-block d-sm-none" />
            <img src={getHomeBannerUS(nameZoneUS) && getHomeBannerUS(nameZoneUS).desktop} className="imgBaner d-none d-sm-block" />
          </div>
        ) : (
          <div className="bannerImg">
            <img src={`../../../${countryCode}/images/banner_mobile.png`} className="imgBaner d-block d-sm-none" />
            <img src={`../../../${countryCode}/images/banner.png`} className="imgBaner d-none d-sm-block" />
          </div>
        )
      }
      <div className="home-content-banner">
        {
          (countryCode != 'my' && countryCode != 'in') &&
          <>
            <h2>
              <FormattedMessage
                id="home.banner.title"
                values={{ br: <br /> }}
              />
            </h2>
            <div className="logo_banner_2">
              <img src={`../../../images/logo_big_2.png`} />
            </div>
          </>
        }
        {
          (countryCode == 'my' || countryCode == 'in') &&
          <>
            <h2>
              <FormattedMessage
                id="home.banner.title"
                values={{ br: <br /> }}
              />
            </h2>
            <div className="logo_banner">
              <img src={`../../../images/logo_big.png`} />
            </div>
          </>
        }
        <ul>
          <li>
            <a href={countryCode !== 'jo' ? link.googlePlay : link.appStoreJordan} target="_blank">
              <BtnAndroid intl={intl} />
            </a>
          </li>
          <li>
            <a href={countryCode !== 'jo' ? link.appStore : link.appStoreJordan} target="_blank">
              <BtnIos intl={intl} />
            </a>
          </li>
          {
            appGalleryPax &&
            <li>
              <a href={appGalleryPax} target="_blank">
                <BtnHuawei intl={intl} />
              </a>
            </li>
          }
        </ul>
      </div>
    </section>
  )
}

export default injectIntl(Banner)

import React, { useContext } from "react"
import { graphql } from "gatsby"

import SignLayout from './../layouts/SignLayout';

import Banner from '../containers/Banner/Banner';
import FleetService from '../containers/FleetService/FleetService';
import WeTakeYou from '../containers/WeTakeYou/WeTakeYou';
import YourOwnBoss from '../containers/YourOwnBoss/YourOwnBoss';
import FleetCover from '../containers/FleetCover/FleetCover';
import DriverSignUp from '../containers/DriverSignUp/DriverSignUp';

import SEO from '../components/SEO/SEO';
import { injectIntl } from "gatsby-plugin-intl";
import CountryContext from "../context/CountryContext";


let title = "Get a safe and affordable ride"
let description = "Safe and affordable ride when you travel. Or sign up as GOJO Driver to earn more. Cabs, taxi, outstation cab, auto, car rent, self drive, cab rental, rides, ola, uber, rapido, tour, transfer, airport, railway, trainstation, bus station, travels etc"
let scriptFaceBookPixel = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function()
{n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1088234585047003');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1088234585047003&ev=PageView&noscript=1"
/></noscript>
`

const Index = ({ data: { homeJson }, intl, ...props }) => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations
  const Context = useContext(CountryContext)
  return (
    <SignLayout>
      <SEO 
        title={title} 
        description={description} 
        scriptFaceBookPixel={scriptFaceBookPixel}
      />
      <Banner 
        countryCode={Context.country}
        appGalleryPax={Context.fleetInfo?.fleet?.socialSetting?.appGalleryPax}
        fleetId={Context.fleetId}
        nameZoneUS={Context.nameZoneUS}
      />
      <FleetService 
        countryCode={Context.country}
      />
      <div className="h-container">
        <WeTakeYou 
          countryCode={Context.country}
        />
        <YourOwnBoss 
          countryCode={Context.country}
        />
        <FleetCover 
          countryCode={Context.country}
        />
      </div>
      <DriverSignUp 
        countryCode={Context.country}
      />
    </SignLayout>
  )
}

export default injectIntl(Index)

export const query = graphql`
  query Index($locale: String!) {
    homeJson: allFile(
      filter: { name: { eq: $locale }, relativeDirectory: { eq: "home" } }
      ) {
      edges {
          node {
          childHomeJson {
                masterFleet {
                  title
                  masterFleetSlide {
                    title
                    descriptions
                    image
                  }
                }
                delight {
                  title
                  delightSlide {
                    title
                    descriptions
                    image
                  }
                }
                fleetService {
                  title
                  subtitles
                  descriptions
                }
                weTakeYou {
                  title
                  description
                }
                yourOwnBoss {
                  title
                  description
                }
                fleetCover {
                  title
                  description
                }
              }
          }
      }
      }
  }
`

import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import './WeTakeYou.scss';
import { Link } from 'gatsby';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

class WeTakeYou extends Component {
	render() {
		const countryCode = this.props.countryCode
		return (
			<Row className="we-take-you-row">
				<Col md={{ span: 7, order: 'first' }} >
					<div className="we-take-you image-content">
						<img
							className="we-take-you image"
							src={`../../../${countryCode}/images/img1.png`}
						/>
					</div>
				</Col>
				<Col className="col-right" md={{ span: 5, order: 'last' }} >
					<div className="we-take-you content">
						<div>
							<h1>
								<FormattedMessage 
									id="home.weTakeYou.title"
								/>
							</h1>
							<p>
								<FormattedMessage 
									id="home.weTakeYou.content"
								/>
							</p>
							<div className="we-take-you learn-more" >
								<div className="item-learn-more">
									<Link to={`customer/`}>
										<span className='title-learn-more'><FormattedMessage id="home.learnMore" /></span>
										<span className='icon'>
											<img
												className="icon-image"
												src={`../../../icon/Arrow.svg`}
											/>
										</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		)
	}
}

export default injectIntl(WeTakeYou)
